var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Novo Técnico"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Código","label-for":"codigo"}},[_c('validation-provider',{attrs:{"name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"codigo","type":"text","maxlength":"15"},model:{value:(_vm.tecnico.codigo),callback:function ($$v) {_vm.$set(_vm.tecnico, "codigo", $$v)},expression:"tecnico.codigo"}}),_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalTecnico}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"7"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"h-nome"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-nome","placeholder":"Nome","maxlength":"100"},model:{value:(_vm.tecnico.nome),callback:function ($$v) {_vm.$set(_vm.tecnico, "nome", $$v)},expression:"tecnico.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Login","label-for":"login"}},[_c('validation-provider',{attrs:{"name":"login","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"login","type":"text"},model:{value:(_vm.utilizador.username),callback:function ($$v) {_vm.$set(_vm.utilizador, "username", $$v)},expression:"utilizador.username"}}),_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalUtilizadores}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","maxlength":"256"},model:{value:(_vm.tecnico.email),callback:function ($$v) {_vm.$set(_vm.tecnico, "email", $$v)},expression:"tecnico.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Telemovel","label-for":"h-telemovel"}},[_c('validation-provider',{attrs:{"name":"Telemovel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-telemovel","placeholder":"Telemovel","maxlength":"13"},model:{value:(_vm.tecnico.telemovel),callback:function ($$v) {_vm.$set(_vm.tecnico, "telemovel", $$v)},expression:"tecnico.telemovel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Custo Hora","label-for":"h-horaCusto"}},[_c('validation-provider',{attrs:{"name":"horaCusto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-2",attrs:{"type":"number","placeholder":"0"},model:{value:(_vm.tecnico.horaCusto),callback:function ($$v) {_vm.$set(_vm.tecnico, "horaCusto", $$v)},expression:"tecnico.horaCusto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Valor Hora","label-for":"h-horaPreco"}},[_c('validation-provider',{attrs:{"name":"horaPreco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-2",attrs:{"type":"number","placeholder":"0"},model:{value:(_vm.tecnico.horaPreco),callback:function ($$v) {_vm.$set(_vm.tecnico, "horaPreco", $$v)},expression:"tecnico.horaPreco"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.switchLoc()}}},[_vm._v(" Guardar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Cancelar ")])],1)],1)],1)],1),_c('b-modal',{ref:"modalTecnico",attrs:{"hide-footer":"","size":"xl"}},[_c('selecionarTecnico',{on:{"tecnicoSelecionado":_vm.selectTecnico}})],1),_c('b-modal',{ref:"modalUtilizadores",attrs:{"hide-footer":"","size":"xl"}},[_c('selecionarUtilizador',{on:{"utilizadorSelecionado":_vm.selectUtilizador}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }