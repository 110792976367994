<template>
  <b-card title="Novo Técnico">
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <!-- Código -->
          <b-col
            md="2"
          >
            <b-form-group
              label="Código"
              label-for="codigo"
            >
              <validation-provider
                #default="{ errors }"
                name="Código"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="codigo"
                    v-model="tecnico.codigo"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalTecnico"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
          <b-col md="7">
            <b-form-group
              label="Nome"
              label-for="h-nome"
            >
              <validation-provider
                #default="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-input
                  id="h-nome"
                  v-model="tecnico.nome"
                  placeholder="Nome"
                  maxlength="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="3"
          >
            <b-form-group
              label="Login"
              label-for="login"
            >
              <validation-provider
                #default="{ errors }"
                name="login"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="login"
                    v-model="utilizador.username"
                    type="text"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalUtilizadores"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="tecnico.email"
                  type="email"
                  maxlength="256"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Telemovel"
              label-for="h-telemovel"
            >
              <validation-provider
                #default="{ errors }"
                name="Telemovel"
                rules="required"
              >
                <b-form-input
                  id="h-telemovel"
                  v-model="tecnico.telemovel"
                  placeholder="Telemovel"
                  maxlength="13"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="Custo Hora"
              label-for="h-horaCusto"
            >
              <validation-provider
                #default="{ errors }"
                name="horaCusto"
                rules="required"
              >
                <b-form-input
                  v-model="tecnico.horaCusto"
                  type="number"
                  class="mb-2"
                  placeholder="0"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Valor Hora"
              label-for="h-horaPreco"
            >
              <validation-provider
                #default="{ errors }"
                name="horaPreco"
                rules="required"
              >
                <b-form-input
                  v-model="tecnico.horaPreco"
                  type="number"
                  class="mb-2"
                  placeholder="0"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="switchLoc()"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>

    <b-modal
      ref="modalTecnico"
      hide-footer
      size="xl"
    >
      <selecionarTecnico @tecnicoSelecionado="selectTecnico" />
    </b-modal>
    <b-modal
      ref="modalUtilizadores"
      hide-footer
      size="xl"
    >
      <selecionarUtilizador @utilizadorSelecionado="selectUtilizador" />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroupPrepend, BInputGroup, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import router from '@/router'
import selecionarTecnico from '../../Listagens/Tecnicos/SelecionarTecnico.vue'
import selecionarUtilizador from '../../Listagens/Utilizadores/SelecionarUtilizador.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    selecionarTecnico,
    selecionarUtilizador,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      tecnico: {
        codigo: '',
        nome: '',
        email: '',
        telemovel: '',
        horaCusto: 0,
        horaPreco: 0,
        login: '',
      },
      utilizador: {
        id: '',
        username: '',
        nome: '',
      },
    }
  },

  created() {
    localize('pt', pt)
    if (router.currentRoute.params.id !== undefined) {
      this.getOneTencico()
    }
  },
  methods: {
    getOneTencico() {
      const tecnicoId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
      const apiUrl = `/api/v1/tecnicos/${tecnicoId}/complete`

      axios.get(apiUrl)
        .then(res => { this.selectTecnico(res.data) })
        .catch(error => {
          // Lide com erros aqui, se necessário
        })

      this.$forceUpdate()
    },

    showModalUtilizadores() {
      this.$forceUpdate()
      this.$refs.modalUtilizadores.show()
    },
    hideModalUtilizadores() {
      this.$refs.modalUtilizadores.hide()
    },
    selectUtilizador(row) {
      this.utilizador.id = row.id
      this.utilizador.nome = row.nomeUtilizador
      this.utilizador.username = row.username
      this.tecnico.login = row.id
      this.hideModalUtilizadores()
    },

    selectTecnico(row) {
      this.tecnico.login = row.login
      this.tecnico.codigo = row.codigo
      this.tecnico.nome = row.nome
      this.tecnico.email = row.email
      this.tecnico.telemovel = row.telemovel
      this.tecnico.horaCusto = row.horaCusto
      this.tecnico.horaPreco = row.horaPreco
      this.tecnico.id = row.id
      this.utilizador.id = row.login
      this.utilizador.nome = row.nomeUtilizador
      this.utilizador.username = row.username
      this.hideModalTecnico()
    },

    showModalTecnico() {
      this.$refs.modalTecnico.show()
    },
    hideModalTecnico() {
      this.$refs.modalTecnico.hide()
    },

    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.guardarTecnico()
        }
      })
    },

    guardarTecnico() {
      axios.put(`/api/v1/tecnicos/${this.tecnico.id}`, this.tecnico)
        .then(this.showToast('success', 'CheckIcon'))
      this.$forceUpdate()
    },

    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },

  },
}
</script>
